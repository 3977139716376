import React, { Component } from "react";
import { connect } from "react-redux";
import { DropdownButton, MenuItem, Dropdown } from "react-bootstrap";
import { logout } from "../../actions/authActions.js";
import { currencyAction, selectCurrencyAction } from "../../actions/currencyActions.js";
import * as Env from "../../Env";
import Trans from "../../locale/Translate";
import { browserHistory } from "react-router";

//asset
import HoteripLogo from "../../assets/images/logo.png";
import HisLogo from "../../assets/images/his-hotels.png";
import indonesia from "../../assets/images/indonesia.png";
import japan from "../../assets/images/japan.png";
import english from "../../assets/images/english.png";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      display_name: "",
      currencies: [],
      selectedCurrency: null,
      show_currency: true,
      showAgentCurrency: false,
      isMobileView: false
    };

    this._logout = this._logout.bind(this);
    this.handleLanguage = this.handleLanguage.bind(this);
    this.handleCurrency = this.handleCurrency.bind(this);
    this.updateViewMode = this.updateViewMode.bind(this);
  }

  handleLanguage(code) {
    localStorage.setItem("language", code);
    window.location.reload(true);
  }

  handleCurrency(code) {
    localStorage.setItem("currency", code);
    this.props.dispatch(selectCurrencyAction(code));
    window.location.reload(true);
  }

  _logout() {
    let current_url = "/login";
    this.props.dispatch(logout(current_url));
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateViewMode); // listen to resize events
    this.updateViewMode(); // check view mode on mount
    if (browserHistory.getCurrentLocation()) {
      if (browserHistory.getCurrentLocation().pathname) {
        if (browserHistory.getCurrentLocation().pathname === "/booking") {
          this.setState({ show_currency: false, showAgentCurrency: true });
        }
        if (browserHistory.getCurrentLocation().pathname === "/payment") {
          this.setState({ show_currency: false, showAgentCurrency: true });
        }
      }
    }
    if (this.props.isLoggedIn) {
      this.props.dispatch(currencyAction());
      this.setState({ display_name: this.props.user.user.display_name });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateViewMode); // clean up listener on unmount
  }

  updateViewMode() {
    // cek ukuran layar, jika <660px maka mobile view
    if (window.innerWidth <= 660) {
      this.setState({ isMobileView: true });
    } else {
      this.setState({ isMobileView: false });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isLoggedIn) {
      this.setState({ currencies: nextProps.currency.data, selectedCurrency: nextProps.currency.selectedCurrency });
      this.setState({ display_name: nextProps.user.user.display_name });
    }
  }

  render() {
    const { display_name, isMobileView } = this.state;

    return (
      <div>
        <nav className="navbar navbar-static-top custom">
          <div className="container">
            {isMobileView ? (
              <div className="mobile-nav">
                <div className="col-md-4">
                  <div className="navbar-header">
                    <button
                      type="button"
                      className="navbar-toggle collapsed"
                      data-toggle="collapse"
                      data-target="#bs-example-navbar-collapse-1"
                      aria-expanded="false"
                    >
                      <span className="sr-only">Toggle navigation</span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                    </button>
                    <a href="/">
                      <img
                        role="presentation"
                        src={HisLogo}
                        alt="logo"
                        width={160}
                        height={"auto"}
                        style={{ marginRight: "10px" }}
                      />
                    </a>
                  </div>
                </div>
                <div className="col-md-8 icon-mobile-view">
                  {Env.isLoggedIn() ? (
                    <div>
                      {this.state.currencies.length > 0 && this.state.show_currency ? (
                        <Dropdown id="dropdown-curr" bsSize="large">
                          <Dropdown.Toggle className="noBorderMenuHeader" noCaret>
                            <div style={{ display: "block", height: "2px" }}></div>
                            {this.state.selectedCurrency}
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="super-colors">
                            {this.state.currencies.map((data, i) => (
                              <MenuItem key={i} onClick={() => this.handleCurrency(data.code)}>
                                <span>{data.symbol}</span> {data.name}
                              </MenuItem>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      ) : (
                        <span
                          class="noBorderMenuHeader btn btn-default"
                          style={{ padding: "10px 16px", fontSize: "18px", position: "relative", color: "black" }}
                          disabled
                        >
                          {this.state.selectedCurrency}
                        </span>
                      )}
                    </div>
                  ) : null}
                </div>
                <div className="dropdown-mobile">
                  {Env.isLoggedIn() ? (
                    <DropdownButton
                      bsSize="large"
                      title={<i className="fa fa-bars" style={{ fontSize: "24px" }} />}
                      id={`dropdown-profile`}
                    >
                      <MenuItem>
                        {Env.isLoggedIn() ? (
                          <>
                            <img
                              style={{ objectFit: "contain", padding: "0px 20px", cursor: "pointer" }}
                              role="presentation"
                              src={Env._default.lang_header}
                            />
                          </>
                        ) : null}
                      </MenuItem>
                      <MenuItem href="/profile">{Trans.data.profile}</MenuItem>
                      {/* <MenuItem href="/deposit">{Trans.data.deposit}</MenuItem> */}
                      {this.props.isLoggedIn && this.props.user.user.authority_accounting == 1 && (
                        <MenuItem href="/booking-history">{Trans.data.my_bookings}</MenuItem>
                      )}
                      <MenuItem divider />
                      <MenuItem onClick={this._logout}>{Trans.data.sign_out}</MenuItem>
                    </DropdownButton>
                  ) : null}
                </div>
              </div>
            ) : (
              <>
                <div className="navbar-header">
                  <button
                    type="button"
                    className="navbar-toggle collapsed"
                    data-toggle="collapse"
                    data-target="#bs-example-navbar-collapse-1"
                    aria-expanded="false"
                  >
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </button>
                  <a href="/">
                    <img
                      role="presentation"
                      src={HisLogo}
                      alt="logo"
                      width={160}
                      height={"auto"}
                      style={{ marginRight: "10px" }}
                    />
                  </a>
                </div>
                <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                  <ul className="nav navbar-right custom">
                    <li style={{ padding: "10px 15px" }}>&nbsp;</li>
                    {Env.isLoggedIn() ? (
                      <>
                        <img
                          style={{ objectFit: "contain", padding: "0px 20px", cursor: "pointer" }}
                          role="presentation"
                          src={Env._default.lang_header}
                        />
                      </>
                    ) : null}
                    {Env.isLoggedIn() ? (
                      <div>
                        {this.state.currencies.length > 0 && this.state.show_currency ? (
                          <Dropdown id="dropdown-curr" bsSize="large">
                            <Dropdown.Toggle className="noBorderMenuHeader" noCaret>
                              <div style={{ display: "block", height: "2px" }}></div>
                              {this.state.selectedCurrency}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="super-colors">
                              {this.state.currencies.map((data, i) => (
                                <MenuItem key={i} onClick={() => this.handleCurrency(data.code)}>
                                  <span>{data.symbol}</span> {data.name}
                                </MenuItem>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        ) : (
                          <span
                            class="noBorderMenuHeader btn btn-default"
                            style={{ padding: "10px 16px", fontSize: "18px", position: "relative", color: "black" }}
                            disabled
                          >
                            {this.state.selectedCurrency}
                          </span>
                        )}
                      </div>
                    ) : null}
                    {Env.isLoggedIn() ? (
                      <DropdownButton bsSize="large" title={display_name} id={`dropdown-profile`}>
                        <MenuItem href="/profile">{Trans.data.profile}</MenuItem>
                        {/* <MenuItem href="/deposit">{Trans.data.deposit}</MenuItem> */}
                        {this.props.isLoggedIn && this.props.user.user.authority_accounting == 1 && (
                          <MenuItem href="/booking-history">{Trans.data.my_bookings}</MenuItem>
                        )}
                        <MenuItem divider />
                        <MenuItem onClick={this._logout}>{Trans.data.sign_out}</MenuItem>
                      </DropdownButton>
                    ) : null}
                  </ul>
                </div>
              </>
            )}
          </div>
        </nav>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { isLoggedIn, user } = state.authReducer;
  return {
    isLoggedIn,
    user,
    currency: state.currencyReducer
  };
};

export default connect(mapStateToProps)(Header);
