import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import Trans from '../../locale/Translate.js';
import * as Env from '../../Env'

import check_icon from '../../assets/images/icon/check.png';
import uncheck_icon from '../../assets/images/icon/uncheck.png';
import info_icon from '../../assets/images/icon/info.png';
import additional from '../../assets/images/icon/benefit_room_small.png';
import surchargeImg from '../../assets/images/icon/surcharge.png';
import { connect } from 'react-redux';

var moment = require('moment');
var shortid = require('shortid');

class RoomPromotions extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: false,
			itemsToShow: 1,
			limit: 2,
			short_id: null,
			showComponents: false,
			rooms: []
		}

		this.reduceSurcharge = this.reduceSurcharge.bind(this)
	}

	componentWillMount() {
		this.setState({ rooms: this.props.result })
	}

	reduceSurcharge(value, type) {
		let data;

		if (type === 'adult') {
			data = value.reduce((prevVal, currVal) => Number(prevVal) + Number(currVal.adult_price), 0);
		}

		if (type === 'child') {
			data = value.reduce((prevVal, currVal) => Number(prevVal) + Number(currVal.child_price), 0);
		}

		return Env.format(data);
	}

	render() {
		const { result } = this.props;

		var lang = 'id';
		switch (localStorage.getItem('language')) {
			case 'en-us':
				lang = 'en-gb';
				break;
			case 'ja':
				lang = 'ja';
				break;
			default:
				lang = 'id';
				break;
		}

		return (
			<>
				<div>
					{this.state.rooms.campaigns.sort((a, b) => (a.price_total) - (b.price_total)).slice(0, this.state.limit).map((data, i) => {
						const surchargeDesc = data.surcharges.length > 0 ? data.surcharges[0].description : ''
						if (!data.discount_value) {
							return (
								<div id="childRoomList" data-toggle="collapse" href={'#collapse-' + this.state.short_id + data.id} aria-expanded="false" onClick={() => this.setState({ short_id: shortid.generate() })}>
									<div className="row">
										<div className="col-sm-5">
											<div className="content-list-hotel">
												<ul className="unorderedCustom">
													{result.is_breakfast_included ?
														<li>
															<span><img src={check_icon} alt="room icon" /> {" "}{Trans.data.breakfast_include}</span>
														</li>
														: <li><del><span data-tip data-for={'without_breakfast' + this.state.short_id + data.id} style={{ cursor: 'pointer' }}><img src={uncheck_icon} alt="room icon" /> {Trans.data.without_breakfast}</span></del></li>
													}
													<li>
														<img src={info_icon} alt="room icon" />
														<span data-tip data-for={'cancellation' + this.state.short_id + data.id} style={{ display: 'inline', cursor: 'pointer' }}>
															{` ${Trans.data.cancellation_policy}`}
														</span>
													</li>
													{data.surcharge_price_total ?
														<li>
															<span data-tip data-for={'surcharges' + this.state.short_id + data.id} style={{ cursor: 'pointer' }}>
																<img src={surchargeImg} alt="surcharge" />
																{" "}{Trans.data.additional_cost}
															</span>
														</li>
														: null}
												</ul>
												<ReactTooltip id={'without_breakfast' + this.state.short_id + data.id} place="bottom" >
													<ul className="unorderedCustom">
														<li style={{ listStyle: 'disc' }}>{Trans.data.adult_breakfast_price} : {Env.format(result.adult_breakfast_price)}</li>
														<li style={{ listStyle: 'disc' }}>{Trans.data.child_breakfast_price} : {Env.format(result.child_breakfast_price)}</li>
													</ul>
												</ReactTooltip>

												{data.surcharge_price_total ?
													<ReactTooltip id={'surcharges' + this.state.short_id + data.id} place="bottom">
														<ul className="unorderedCustom">
															<li style={{ listStyle: 'disc' }}>{Trans.data.price_for} {surchargeDesc} {Trans.data.adult} : {this.reduceSurcharge(data.surcharges, 'adult')} </li>
															<li style={{ listStyle: 'disc' }}>{Trans.data.price_for} {surchargeDesc} {Trans.data.children} : {this.reduceSurcharge(data.surcharges, 'child')} </li>
														</ul>
													</ReactTooltip> : null}
												<ReactTooltip id={'cancellation' + this.state.short_id + data.id} place="bottom">
												{
													data.cancelation ? (
													<ul className="unorderedCustom">
														{data.cancelation.level_1_cancelation_rule && <li style={{ listStyle: 'disc' }}>{data.cancelation.level_1_cancelation_rule.name}</li>}
														{data.cancelation.level_2_cancelation_rule && <li style={{ listStyle: 'disc' }}>{data.cancelation.level_2_cancelation_rule.name}</li>}
														{data.cancelation.level_3_cancelation_rule && <li style={{ listStyle: 'disc' }}>{data.cancelation.level_3_cancelation_rule.name}</li>}
													</ul>
													) : (<ul className="unorderedCustom">
															{data.cancelations.map((item, i) => (
																<li style={{ listStyle: 'disc' }}>{item.name}</li>
															))}
														</ul>)
												}
												</ReactTooltip>

											</div>
										</div>
										<div className="col-sm-4">
											<div className="price-tag">
												<h3>{Env.format(data.price_average)} <small> {Trans.data.avg_pernight}</small></h3>
												<span className="text-primary" style={{ fontSize: '12px' }}>{Trans.data.price_inclusive_of_tax}</span>
											</div>
										</div>
										<div className="col-sm-3">
											{data.discount_value ? <div className="discountRate">{<strong>{Trans.data.discount} {data.discount_value}%</strong>}</div> : null}
											{this.props.isLoggedIn && this.props.auth.user.authority_booking == 1 && <div style={{ marginTop: '10px', textAlign: 'center' }}>
												<div href="#" onClick={() => this.props.onNavigate(data, { id: result.id, name: result.name, photo_basename: result.photo ? result.photo.basename_url : null, is_breakfast_included: result.is_breakfast_included }, data.cancelation ? data.cancelation : data.cancelations)} className="btn btn-promo btn-lg" role="button" style={{ width: '100%' }}>{Trans.data.book_now}</div>
											</div>}
										</div> {/* /.col-sm-3 */}
									</div> {/* /.row */}

									{/*Room Default Detail*/}
									<div className="row">
										<div className="col-sm-12">
											<div className="collapse" id={'collapse-' + this.state.short_id + data.id}>
												{data.occupancy_rates.map((res, i) =>
													<div className="table-responsive" key={i}>
														<span style={{ width: '75px' }}>{Trans.data.room + '-'}{i + 1}</span>
														<table className="table table-sm table-bordered" >
															<thead>
																<tr>
																	<th>{Trans.data.day}</th>
																	{res.rates.map((rate, rateidx) => <th key={`${i}${rateidx}`}>{moment(rate.date).locale(lang).format("ddd, DD MMM YYYY")} </th>)}
																</tr>
															</thead>
															<tbody>
																<tr className="success">
																	<td>{Trans.data.price_and_stock}</td>
																	{res.rates.map((rate, rateIdx) =>
																		<td key={`${i}${rateIdx}`}>
																			<span className="price-calendar">
																				{Env.format(rate.price)}
																			</span>
																			<span className="stock-calendar">{rate.stock} {Trans.data.room_remaining}</span>
																		</td>
																	)}
																</tr>
															</tbody>
														</table>
													</div>
												)}
											</div>
										</div>
									</div>
								</div>
							)
						} else {
							return (
								<div id="childRoomList" data-toggle="collapse" href={'#collapse-' + this.state.short_id + data.id} aria-expanded="false" onClick={() => this.setState({ short_id: shortid.generate() })}>
									<div className="row">
										<div className="col-sm-5">
											{data.discount_value ?
												<div className="discountSearch" style={{ marginTop: '0px' }}>
													<span className="fa-stack">
														<i className="fa fa-circle fa-stack-2x"></i>
														<i className="fa fa-tag fa-stack-1x fa-inverse"></i>
													</span>

													{" "}<strong>{Trans.data.discount} {data.discount_value}%</strong>
												</div>
												: null}

											<ul className="unorderedCustom">
												<li>
													{result.is_breakfast_included ?
														<span><img src={check_icon} alt="room icon" /> {" "}{Trans.data.breakfast_include}</span>
														:
														<del><span data-tip data-for={'withouts_breakfast' + this.state.short_id + data.id} style={{ cursor: 'pointer' }}><img src={uncheck_icon} alt="room icon" /> {Trans.data.without_breakfast}</span></del>
													}
												</li>
												<li><img src={info_icon} alt="room icon" /><span data-tip data-for={'cancellation_policy' + this.state.short_id + data.id} style={{ cursor: 'pointer' }}> {Trans.data.cancellation_policy}</span></li>
												{data.surcharge_price_total ?
													<li>
														<span data-tip data-for={'surcharges' + this.state.short_id + data.id} style={{ cursor: 'pointer' }}>
															<img src={surchargeImg} alt="surcharge" />
															{" "}{Trans.data.additional_cost}
														</span>
													</li>
													: null}
											</ul>

											{data.surcharge_price_total ?
												<ReactTooltip id={'surcharges' + this.state.short_id + data.id} place="bottom">
													<ul className="unorderedCustom">
														<li style={{ listStyle: 'disc' }}>{Trans.data.price_for} {surchargeDesc} {Trans.data.adult} : {this.reduceSurcharge(data.surcharges, 'adult')} </li>
														<li style={{ listStyle: 'disc' }}>{Trans.data.price_for} {surchargeDesc} {Trans.data.children} : {this.reduceSurcharge(data.surcharges, 'child')} </li>
													</ul>
												</ReactTooltip> : null}

											<ReactTooltip id={'withouts_breakfast' + this.state.short_id + data.id} place="bottom" >
												<ul className="unorderedCustom">
													<li style={{ listStyle: 'disc' }}>{Trans.data.adult_breakfast_price} : {Env.format(result.adult_breakfast_price)}</li>
													<li style={{ listStyle: 'disc' }}>{Trans.data.child_breakfast_price} : {Env.format(result.child_breakfast_price)}</li>
												</ul>
											</ReactTooltip>

											<ReactTooltip id={'cancellation_policy' + this.state.short_id + data.id} place="bottom">
											{
												data.cancelation ? (
												<ul className="unorderedCustom">
													{data.cancelation.level_1_cancelation_rule && <li style={{ listStyle: 'disc' }}>{data.cancelation.level_1_cancelation_rule.name}</li>}
													{data.cancelation.level_2_cancelation_rule && <li style={{ listStyle: 'disc' }}>{data.cancelation.level_2_cancelation_rule.name}</li>}
													{data.cancelation.level_3_cancelation_rule && <li style={{ listStyle: 'disc' }}>{data.cancelation.level_3_cancelation_rule.name}</li>}
												</ul>
												) : (<ul className="unorderedCustom">
														{data.cancelations.map((item, i) => (
															<li style={{ listStyle: 'disc' }}>{item.name}</li>
														))}
													</ul>)
											}
											</ReactTooltip>

											{data.benefits.length > 0 ?
												<div className="aditional-benefits">
													<span data-tip data-for={'aditional_benefit' + this.state.short_id + data.id} className="toltips" style={{ cursor: 'pointer' }}>
														<img src={additional} alt="additional benefit" /> {Trans.data.additional_benefit}
													</span>
													<ReactTooltip id={'aditional_benefit' + this.state.short_id + data.id} place="bottom" >
														<ul className="unorderedCustom">
															{data.benefits.map((list) => <li key={list.id} style={{ listStyle: 'disc' }}>{list.name}</li>)}
														</ul>
													</ReactTooltip>
												</div>
												: null}
										</div>
										<div className="col-sm-4">
											{(() => {
												const baseCampaign = data.base_campaign_id ? result.campaigns.find(camp => camp.id == data.base_campaign_id) : null;
												return <div className="price-tag" >
													<React.Fragment key={i}>
														{baseCampaign ? <del>{Env.format(baseCampaign.price_average_exclude_surcharge)}</del> : null}
														<span style={{ fontSize: '11px' }}>{Trans.data.avg_pernight}</span>
														<h3>{Env.format(data.price_average)} <small> {Trans.data.avg_pernight}</small></h3>
													</React.Fragment>
													<span className="text-primary" style={{ fontSize: '12px' }}>{Trans.data.price_inclusive_of_tax}</span>
												</div>
											})()}
										</div>
										<div className="col-sm-3">
											{this.props.isLoggedIn && this.props.auth.user.authority_booking == 1 && <div style={{ marginTop: '30px', textAlign: 'center' }}>
												<div href="#" onClick={() => this.props.onNavigate(data, { id: result.id, name: result.name, photo_basename: result.photo ? result.photo.basename_url : null, is_breakfast_included: result.is_breakfast_included }, data.cancelation ? data.cancelation : data.cancelations)} className="btn btn-promo btn-lg" role="button" style={{ width: '100%' }}>{Trans.data.book_now}</div>
											</div>}
										</div>
									</div>

									{/* Room Promotion  Detail*/}
									<div className="row">
										<div className="col-sm-12">
											<div className="collapse" id={'collapse-' + this.state.short_id + data.id}>
												{data.occupancy_rates.map((res, i) =>
													<div className="table-responsive" key={i}>
														<span style={{ width: '75px' }}>{Trans.data.room + '-'}{i + 1}</span>
														<table className="table table-sm table-bordered" >
															<thead>
																<tr>
																	<th>{Trans.data.day}</th>
																	{res.rates.map((rate, rateidx) => <th key={`${i}${rateidx}`}>{moment(rate.date).locale(lang).format("ddd, DD MMM YYYY")} </th>)}
																</tr>
															</thead>
															<tbody>
																<tr className="success">
																	<td>{Trans.data.price_and_stock}</td>
																	{res.rates.map((rate, rateIdx) =>
																		<td key={`${i}${rateIdx}`}>
																			<span className="price-calendar">
																				{Env.format(rate.price)}
																			</span>
																			<span className="stock-calendar">{rate.stock} {Trans.data.room_remaining}</span>
																		</td>
																	)}

																</tr>
															</tbody>
														</table>
													</div>
												)}
											</div>
										</div>
									</div>
								</div>
							)
						}
					})}
					{result.campaigns.length > 2 && (
						<a className='btn show-more-room' onClick={() => this.setState({ limit: this.state.limit > 2 ? 2 : result.campaigns.length })}>
							{this.state.limit > 2 ? (
								<span>Show less <i className='fa fa-caret-up'></i></span>
							) : (
								<span>Show more <i className='fa fa-caret-down'></i></span>
							)}
						</a>
					)}
				</div >
			</>
		)
	}
}

const mapStateToProps = state => {
  const { user: auth, isLoggedIn } = state.authReducer;
  return {
	isLoggedIn,
	auth
  };
};

export default connect(mapStateToProps)(RoomPromotions);